import { makeAutoObservable } from "mobx";
import { IUpdateStatusesRequest } from "../services/integration/IntegrationsRequest";
import { IChannel, IManagerChannels } from "../services/channel/ICnannelResponse";

class TransferredChannelsStore {
  tableItems: IChannel[] = [];
  tableAccordionItems: IManagerChannels[] = [];
  filters: Record<string, string | number> = {};
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  private getNestedValue(obj: any, path: string): any {
    return path.split(".").reduce((value, key) => value?.[key], obj);
  }

  private applyFilters(item: IChannel): boolean {
    return Object.keys(this.filters).every((key) => {
      const filterValue = this.filters[key];

      if (filterValue === null || filterValue === undefined) return true;

      const itemValue = this.getNestedValue(item, key === "status" ? "status.code" : key);

      if (typeof filterValue === "string" && filterValue.includes(",")) {
        const filterValuesArray = filterValue.split(",").map((value) => value.trim());

        // Фильтрация по диапазону дат
        if (key === "dateGiven") {
          const [startDateString, endDateString] = filterValuesArray;
          const startDate = new Date(startDateString);
          const endDate = new Date(endDateString);

          // Приведение обеих дат к началу дня для корректного сравнения
          const itemDate = new Date(itemValue as string);
          const itemDateStartOfDay = new Date(itemDate.setHours(0, 0, 0, 0));
          const startDateStartOfDay = new Date(startDate.setHours(0, 0, 0, 0));
          const endDateEndOfDay = new Date(endDate.setHours(23, 59, 59, 999));

          // Сравнение с диапазоном дат (включительно)
          return itemDateStartOfDay >= startDateStartOfDay && itemDateStartOfDay <= endDateEndOfDay;
        }

        // Для остальных полей проверяем, содержит ли хотя бы одно значение фильтра значение из данных
        return filterValuesArray.includes(String(itemValue));
      }

      // Обычная проверка для одиночных значений фильтра
      return String(itemValue) === String(filterValue);
    });
  }

  get filteredTableItems() {
    return this.tableItems.filter(this.applyFilters.bind(this));
  }

  get filteredTableAccordionItems() {
    return this.tableAccordionItems.map((managerItem) => ({
      ...managerItem,
      channels: managerItem.channels.filter(this.applyFilters.bind(this)),
    }));
  }

  setFilters = (filters: Record<string, string | number>) => {
    this.isLoading = true;
    setTimeout(() => {
      this.filters = filters;
      this.isLoading = false;
    }, 0); // Добавляем искусственную задержку для индикации загрузки
  };

  resetFilters = () => {
    this.isLoading = true;
    setTimeout(() => {
      this.filters = {};
      this.isLoading = false;
    }, 0); // Добавляем искусственную задержку для индикации загрузки
  };

  setTableItems(items: IChannel[]) {
    this.tableItems = items;
  }

  setTableAccordionItems(items: IManagerChannels[]) {
    this.tableAccordionItems = items;
  }

  changeField(option: IUpdateStatusesRequest) {
    const { field, id, new_value, update_id } = option;
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          [field]: new_value,
        };
        return newItem;
      } else return item;
    });
  }

  changeAccordionField = (option: IUpdateStatusesRequest) => {
    const { field, id, new_value, update_id } = option;
    this.tableAccordionItems = this.tableAccordionItems.map((managerItem) => ({
      ...managerItem,
      channels: managerItem.channels.map((channel) => {
        if (channel.id === id) {
          const newItem = {
            ...channel,
            update_id,
            [field]: new_value,
          };
          return newItem;
        }
        return channel;
      }),
    }));
  };

  deleteTransferredChannelsItem = (id: number) => {
    if (this.tableItems) {
      this.tableItems = this.tableItems.filter((item) => item.id !== id);
    }
  };
  deleteTransferredAccordionChannelsItem = (value: string, id: number) => {
    const tableAccordionItems = this.tableAccordionItems;
    if (tableAccordionItems) {
      if (value !== "to_work" && value !== "peredan" && value !== "bargain") {
        this.tableAccordionItems = tableAccordionItems.map((managerItem) => {
          return {
            ...managerItem,
            channels: managerItem.channels.filter((channel) => channel.id !== id),
          };
        });
      }
    }
  };

  deleteTransferredChannelsItemByStatus = (status: string, id: number) => {
    if (this.tableItems) {
      if (status !== "peredan") {
        this.tableItems = this.tableItems.filter((el) => el.id !== id);
      }
    }
  };

  changeAccordionChannelUpdateIdItem = (id: number, update_id: number, type: string) => {
    this.tableAccordionItems = this.tableAccordionItems.map((managerItem) => ({
      ...managerItem,
      channels: managerItem.channels.map((channel) => {
        if (channel.id === id) {
          const newItem = {
            ...channel,
            update_id,
            status: { code: type },
          };
          return newItem;
        }
        return channel;
      }),
    }));
  };

  addReleaseByManager = (id: number, field: string, update_id: number, value: number) => {
    this.tableAccordionItems = this.tableAccordionItems.map((managerItem) => ({
      ...managerItem,
      channels: managerItem.channels.map((channel) => {
        if (channel.id === id) {
          const newItem = {
            ...channel,
            update_id,
            [field]: { id: value },
          };
          return newItem;
        }
        return channel;
      }),
    }));
  };

  changeUpdateIdItem = (id: number, update_id: number, type: string) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          status: { code: type },
        };
        return newItem;
      }
      return item;
    });
  };

  addTransferredChannelsItem = (item: IChannel) => {
    if (this.tableAccordionItems) {
      const manager = this.tableAccordionItems.find(
        (manager) => manager.managerId === item.releaseById.toString()
      );
      if (manager) {
        manager.channels.unshift(item);
      }
    }
  };
}

export default new TransferredChannelsStore();
